<template>
    <div>
        <LiefengContent>
            <template v-slot:title>
                {{ "居民数据初始化" }}
                <span class="eye" @click.stop="changeEye">
                    <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
                </span>
                <span style="margin-left: 20px; color: #00bfbf">{{ $route.query.name }}</span>
            </template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true">
                    <!-- <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button> -->
                    <!-- <Button type="primary" style="margin-right: 10px" @click="clearData">清空数据</Button> -->
                    <Button type="primary" style="margin-right: 10px" @click="switchData">转换原始数据</Button>
                    <Button type="info" style="margin-right: 10px" @click="openOrg">初始化社区架构</Button>
                    <!-- <Button type="info" style="margin-right: 10px" @click="openDown">初始化民情档案</Button> -->
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="table.talbeColumns"
                    :tableData="table.tableData"
                    :loading="table.loading"
                    :fixTable="true"
                    :curPage="table.page"
                    :total="table.total"
                    :pagesizeOpts="[500, 1000, 1500, 2000]"
                    :page-size="table.pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import Bus from "@/utils/Bus"

export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengCascaderCity },
    data() {
        return {
            search: {},
            table: {
                talbeColumns: [
                    {
                        key: "houseName",
                        align: "center",
                        title: "门牌号",
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.houseName,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.changeValue(e.target.value, params.row)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "beloneGrid",
                        align: "center",
                        title: "所属网格",
                    },
                    {
                        key: "memberName",
                        align: "center",
                        title: "姓名",
                    },
                    {
                        key: "idNum",
                        align: "center",
                        title: "身份证号",
                    },
                    {
                        key: "city",
                        align: "center",
                        title: "是否本市居民",
                    },
                    {
                        key: "come",
                        align: "center",
                        title: "是否来穗人员",
                    },
                    {
                        key: "phone",
                        align: "center",
                        title: "联系电话",
                    },
                    // {
                    //     key:'gridName',
                    //     align:'center',
                    //     title:'重点人员情况'
                    // },
                    {
                        key: "syncOrg",
                        align: "center",
                        title: "初始化社区架构",
                        render: (h, params) => {
                            return h("div", {}, params.row.syncOrg && params.row.syncOrg == 1 ? "是" : "否")
                        },
                    },
                    {
                        key: "syncUser",
                        align: "center",
                        title: "初始化民情档案",
                        render: (h, params) => {
                            return h("div", {}, params.row.syncUser && params.row.syncUser == 1 ? "是" : "否")
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 500,
                currentPage: 1,
            },
            timer: null,
            hasSensitive:false,
        }
    },

    async created() {
        await this.getList()
        this.getList()
    },
    methods: {
        // 方法部分
        changeEye(){
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
        // 点击初始化社区结构
        openOrg() {
            this.$core.openLayerFrame(
                {
                    type: 2,
                    title: "社区架构初始化拆分",
                    content: `/page#/communityinitiali?name=${this.$route.query.name}&orgCode=${this.$route.query.orgCode}`,
                    area: ["100%", "100%"],
                },
                () => {
                    this.table.page = this.table.currentPage
                    this.getList()
                }
            )
        },
        // 点击初始化民情档案
        openDown() {
            this.$core.openLayerFrame(
                {
                    type: 2,
                    title: "民情档案初始化拆分",
                    content: `/page#/civilinitialization?name=${this.$route.query.name}&orgCode=${this.$route.query.orgCode}`,
                    area: ["100%", "100%"],
                },
                () => {
                    this.table.page = this.table.currentPage
                    this.getList()
                }
            )
        },
        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.getList()
        },

        // 查询按钮
        searchBtn() {
            // this.table.page = 1;
            // this.table.pageSize = 20;
            // this.getList()
        },
        // 接口部分
        getList() {
            this.table.loading = true
            try {
                this.$get("/syaa/pc/sy/user/gridManage/queryResidentsByPage", {
                    archivesId: "",
                    orgCode: this.$route.query.orgCode,
                    page: this.table.page,
                    pageSize: this.table.pageSize,
                    hasSensitive:this.hasSensitive,
                    orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                }).then(res => {
                    this.table.loading = false
                    if (res && res.code == 200) {
                        if (res.dataList && res.dataList.length) {
                            res.dataList.map(item => {
                                if (item.userType && item.userType != "") {
                                    item.userType = item.userType.split(",")
                                    item.userType.map(items => {
                                        switch (items) {
                                            case "1":
                                                item.alone = "是"
                                                break
                                            case "2":
                                                item.difficulty = "是"
                                                break
                                            case "3":
                                                item.retarded = "是"
                                                break
                                            case "4":
                                                item.ill = "是"
                                                break
                                            case "5":
                                                item.foreign = "是"
                                                break
                                            case "6":
                                                item.else = "是"
                                                break
                                            case "7":
                                                item.sixty = "是"
                                                break
                                            case "8":
                                                item.party = "是"
                                                break
                                            case "9":
                                                item.city = "是"
                                                break
                                            case "10":
                                                item.come = "是"
                                                break
                                        }
                                    })
                                }
                            })
                        }
                        this.table.tableData = res.dataList
                        this.table.total = res.maxCount
                        this.table.currentPage = res.currentPage
                    } else {
                        this.table.loading = false
                        this.$Message.error({
                            content: "获取数据失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch (e) {
                this.table.loading = false
                this.$Message.error({
                    content: "获取数据失败",
                    background: true,
                })
                return
            }
        },
        changeValue(val, row) {
            let data = []
            let newRow = JSON.parse(JSON.stringify(row))
            newRow.houseName = val
            delete newRow.userType
            delete newRow.city
            delete newRow._index
            delete newRow._rowKey
            data.push(newRow)
            if (val != "") {
                this.$core.debounce(
                    () => {
                        this.updateRow(data)
                    },
                    "timer",
                    1000
                )
            } else {
                this.updateRow(data)
            }
        },

        async updateRow(val) {
            await this.$post("/syaa/pc/sy/user/gridManage/updateResidents", val, { "Context-Type": "Application/json" }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.table.page = this.table.currentPage
                    this.getList()
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    this.table.page = this.table.currentPage
                    this.getList()
                    return
                }
            })
        },

        // 清空数据
        clearData() {
            this.$Modal.confirm({
                title: "清空数据提醒",
                content: `需确认数据姓名、手机号、身份证号、门牌号一一对应，若不合格，则可清空数据。`,
                okText: "确定清空",
                cancelText: "取消",
                loading: true,
                onOk: () => {
                    this.$get("/syaa/pc/sy/user/gridManage/clearInitOrgData", {
                        orgCode: this.$route.query.orgCode,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            this.$Message.success({
                                content: '清空成功',
                                background: true,
                            })
                            this.getList()
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },

        //转换原始数据
        switchData() {
            this.$core.openLayerFrame({
                type: 2,
                title: "转换原始数据",
                content: `/page#/switchresidentdata?orgCode=${this.$route.query.orgCode}`,
                area: ["100%", "100%"],
            },()=>{  
                this.table.page = this.table.currentPage
                this.getList()
            })
        },
    },
}
</script>

<style lang="less" scoped>
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>